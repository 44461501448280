import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Download">
    <section className="section-download">

      app download


    </section>
  </Layout>
);

export default Page;

